import React, { Component } from "react";
import { DataGrid, GridRowsProp, GridColDef, GridLoadingOverlay } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Paper from '@mui/material/Paper';
import { v4 as uuidv4 } from 'uuid';
import Avatar from '@mui/material/Avatar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';
import Button from '@mui/material/Button';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Switch from '@mui/material/Switch';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import Tooltip from '@mui/material/Tooltip';


import Pagination from "@mui/material/Pagination";
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid";

export default class TableauPronostics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:this.props.data,
    };
    
  }

  componentWillReceiveProps(props) {
    this.setState({ data: props.data})
  }

  componentDidMount() {
  }

  delete(id){
    this.props.delete(id);
  }

  handleSwitch(id){
    this.props.actif(id);
  }

  select(id){
    this.props.select(id);
  }

 

  /************************************************************************************
   * RENDU
   ************
   
   ***********************************************************************/

  render() {

    function CustomPagination() {
      const apiRef = useGridApiContext();
      const page = useGridSelector(apiRef, gridPageSelector);
      const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    
      return (
        
          <Grid container spacing={2}>
          <Grid item xs={8}>
            
          </Grid>
          <Grid item xs={4}>

              <Pagination
              style={{float:"right"}}
              color="primary"
              count={pageCount}
              page={page + 1}
              onChange={(event, value) => apiRef.current.setPage(value - 1)}
              />

          </Grid>
          </Grid>
      );
    }

   
    function reussite(data){
      var color = "";

        if(data < 0){
          data = "-";
        }else if(data > 75){
          color = "#2D7E43";
        }else if(data > 50 && data <= 75){
          color = "#97BA38";
        }else if(data >= 25 && data <= 50){
          color = "#E8B610";
        }else if(data > 10 && data <= 25){
          color = "#D57B1A";
        }else if(data >= 0 && data <= 10){
          color = "#C53419";
        }

        return  (
          <div align="center">
          <Avatar style={{backgroundColor:color, width:"50px", height:"20px", marginTop:"0.4em"}} variant="square">
            <span class="lato-regular" style={{ fontSize:"14px", color:"white"}}>{data} %</span>
          </Avatar>
          </div>
        )
      
    }

    function point(data){
      var color = "";

        if(data >= 1){
          color = "#2D7E43";
          data = data.toString()
          data = "+ " + data;
        }else if(data < 0){
          color = "#C53419";
          data = data.toString().replace("-", "");
          data = "- " + data;
        }

        return  (
          <div align="center">
          <Avatar style={{backgroundColor:color, width:"30px", height:"20px", marginTop:"0.4em"}} variant="square">
            <span class="lato-regular" style={{ fontSize:"14px", color:"white"}}>{data}</span>
          </Avatar>
          </div>
        )
      
    }

    function rde(data){
      var color = "";

        if(data < 0){
          data = "-";
        }else if(data >= 100){
          color = "#2D7E43";
        }else if(data >= 90 && data < 100){
          color = "#97BA38";
        }else if(data < 90){
          color = "#E8B610";
        }

        return  (
          <div align="center">
          <Avatar style={{backgroundColor:color, width:"55px", height:"20px", marginTop:"0.4em"}} variant="square">
            <span class="lato-regular" style={{ fontSize:"14px", color:"white"}}>{data} %</span>
          </Avatar>
          </div>
        )
      
    }

    function dynamique(data){
    
        return  (
          <div align="center">
          <Avatar style={{backgroundColor:"#3f51b5", width:"50px", height:"20px", marginTop:"0.4em", align:"right"}} variant="square">
            <span class="lato-regular" style={{ fontSize:"14px", color:"white"}}>{data} %</span>
          </Avatar>
          </div>
        )
      
    }

    function selection(data){
      return(
      <div align="center">
      <Avatar style={{backgroundColor:"#3f51b5", width:"50px", height:"20px", marginTop:"0.4em", align:"right"}} variant="square">
        <span class="lato-regular" style={{ fontSize:"14px", color:"white"}}>{data}</span>
      </Avatar>
      </div>
      )
  }

   

    function pronostics(data){
      if(data){
        return (
          <div style={{marginTop:"0.4em"}}>
          <EventAvailableOutlinedIcon size="small"/>
          </div>
        )
      }else{
        return (
          <div style={{marginTop:"0.4em"}}>
          <EventBusyOutlinedIcon size="small"/>
          </div>
        )
      }
    }

    
        
      const columns = [
      { 
        field: 'label',
        headerName:  <span class="lato-regular">#</span>,
        description: "#",
        width: 300,
        renderCell: (params) => (
          <Tooltip title={<span class="lato-regular" style={{fontSize:"14px"}}>{params.row.description}</span>}>
         <div onClick={() => this.select(params.row.id)} style={{cursor:"pointer"}}>
          
          <IconButton size="small" style={{textTransform:"none"}} >
          <ArticleOutlinedIcon fontSize="small" size="small"/>
        </IconButton>
          <span class="lato-regular"> {params.formattedValue}</span>    
        
         </div>
         </Tooltip>   
        )
      },
     
      { 
        field: 'nbCourse',
        headerName:  <span class="lato-regular">Courses jouées</span>,
        description: "#",
        headerAlign:"left",
        align: "left",
        width: 110,
        renderCell: (params) => (
          selection(params.formattedValue)
        )
      },   
      { 
        field: 'chevauxParCourse',
        headerName:  <span class="lato-regular">Chx / crse</span>,
        description: "#",
        align: "center",
        headerAlign:"center",
        width: 90,
        renderCell: (params) => (
          selection(params.formattedValue)
        )
      },   
      { 
        field: 'dynamique',
        headerName:  <span class="lato-regular">Dynamique</span>,
        description: "#",
        align: "center",
        headerAlign:"center",
        width: 90,
        renderCell: (params) => (
          dynamique(params.formattedValue)
         
        )
      },
      
      { 
        field: 'reussiteG',
        headerName:  <span class="lato-regular">Gagnant</span>,
        description: "#",
        align: "center",
        headerAlign:"center",
        width: 90,
        renderCell: (params) => (
          reussite(params.formattedValue)
        )
      },
      
      { 
        field: 'reussitePL',
        headerName:  <span class="lato-regular">Placé</span>,
        description: "#",
        align: "center",
        headerAlign:"center",
        width: 90,
        renderCell: (params) => (
          reussite(params.formattedValue)
        )
      },
     
      /*{ 
        field: 'rendementG',
        headerName:  <span class="lato-regular">Rde. G</span>,
        description: "#",
        align: "center",
        headerAlign:"center",
        width: 90,
        renderCell: (params) => (
          rde(params.formattedValue)
        )
      },
      
      { 
        field: 'rendementPl',
        headerName:  <span class="lato-regular">Rde. Pl</span>,
        description: "#",
        align: "center",
        headerAlign:"center",
        width: 90,
        renderCell: (params) => (
          rde(params.formattedValue)
        )
      },*/
      { 
        field: 'point',
        headerName:  <span class="lato-regular">Pts.</span>,
        description: "#",
        align: "center",
        headerAlign:"center",
        width: 30,
        renderCell: (params) => (
          point(params.formattedValue)
        )
      },
      { 
        field: 'pronostics',
        headerName:  <span class="lato-regular">Pronostics</span>,
        description: "#",
        align: "center",
        headerAlign:"center",
        width: 90,
        renderCell: (params) => (
          pronostics(params.formattedValue)
        )
      },
      { 
        field: 'actif',
        headerName:  <span class="lato-regular">Synthèse</span>,
        description: "#",
        align: "center",
        headerAlign:"center",
        width: 90,
        renderCell: (params) => (
          <Switch
            disabled={params.row.dossier == "Pronostics trotstats" ? true : false}
            onClick={() => this.handleSwitch(params.row.id)}
            size="small"
            checked={params.formattedValue}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        )
      },

      { 
        field: ' ',
        headerName:  <span class="lato-regular"></span>,
        description: "#",
        align: "right",
        width: 10,
        renderCell: (params) => (
          <IconButton disabled={params.row.dossier == "Pronostics trotstats" ? true : false} aria-label="delete" size="small" onClick={() => this.delete(params.row.id)}>
          <DeleteIcon fontSize="small" />
        </IconButton>
        )
      },
      
      
  ]


    return(
      <DataGrid
      density="compact"
      hideFooter={false}
      disableRowSelectionOnClick
      rows={this.state.data}
      columns={columns}
     
      
      getRowClassName={(params) =>
        this.state.selectedRow === params.row.id ? 'selected-row' : ''
      }
      initialState={{
        pagination: { paginationModel: { pageSize: 15 } },
      }}
      rowsPerPageOptions={[]} />


    )
  }
}