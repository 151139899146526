import React, { Component } from "react";
import Box from '@mui/material/Box';
import * as Const from "../Constant";

import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Grid from '@mui/material/Grid';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

import Alert from '@mui/material/Alert';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Snackbar from '@mui/material/Snackbar';
import Drawer from '@mui/material/Drawer';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import TableauHistoriqueMise from "./TableauHistoriqueMise";
import TableauPronosticsJeu from "./TableauPronosticsJeu";
import TableauPronosticsRapport from "./TableauPronosticsRapport";
import ChartLine from "./ChartLine";

export default class PronosticsResultat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:this.props.data,
    };
    
  }

  componentWillReceiveProps(props) {
    this.setState({ data: props.data})
  }

  componentDidMount() {
  }

 

  /************************************************************************************
   * RENDU
   ***********************************************************************************/

  render() {

    return(
      <Card style={{marginTop:"1em"}}>
      <CardHeader
          avatar={
          <Avatar aria-label="recipe">
              P{this.state.data.code}
          </Avatar>
          }
        
          title={<span class="lato-bold" style={{transform:"none", color:"black", fontSize:"15px"}}>{this.state.data.ligne}</span>}
          subheader={<span class="lato-regular">Analyse du cheval cité.</span>}
      />
      <CardContent>
        <div style={{display:"flex"}}>
            <div style={{width:"50vw", marginRight:"1em"}}>
              <TableauPronosticsJeu data={this.state.data} />
            </div>
            <div style={{width:"50vw"}}>
              <TableauPronosticsRapport data={this.state.data} />
            </div>

        </div>
        <div style={{marginTop:"1em"}}>
          <TableauHistoriqueMise data={this.state.data.historiqueMise}/>
        </div>
        <div style={{marginTop:"1em"}}>
        {this.state.data != null && this.state.data.historiqueMise.length >= 5  && (
          <ChartLine data={this.state.data.historiqueMise}/>
        )}
        </div>
        

      </CardContent>

      </Card>


    )
  }
}