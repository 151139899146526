import React, { Component } from "react";
import { DataGrid, GridRowsProp, GridColDef, GridLoadingOverlay } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Paper from '@mui/material/Paper';
import { v4 as uuidv4 } from 'uuid';
import Avatar from '@mui/material/Avatar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';
import Button from '@mui/material/Button';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Grid from '@mui/material/Grid';
import Pagination from "@mui/material/Pagination";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid";

export default class TableauPronosticsRapport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:this.props.data,
    };
    
  }

  componentWillReceiveProps(props) {
    this.setState({ data: props.data})
  }

  componentDidMount() {
  }

  /************************************************************************************
   * RENDU
   ***********************************************************************************/

  render() {

    function rendement(data){
      var color = "";

        if(data < 0){
          data = "0";
        }else if(data >= 100){
          color = "#2D7E43";
        }else if(data >= 90 && data < 100){
          color = "#97BA38";
        }else if(data < 90){
          color = "#e8b610";
        }

        return  (
          <Avatar style={{backgroundColor:color, width:"50px", height:"20px"}} variant="square">
            <span class="lato-regular" style={{ fontSize:"14px", color:"white"}}>{data} %</span>
          </Avatar>
        )
      }
      

    return(
      <div>
      <Table component={Paper}>
        <TableHead>
          <TableRow>
            <TableCell><span class="lato-bold">#</span></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          
            <TableRow>
              <TableCell align="left"><span class="lato-regular">Rapports gagnants touchés (min. / max. / moy.) :</span></TableCell>
              <TableCell><span class="lato-regular">{this.state.data.rapportMinGagnant} / {this.state.data.rapportMaxGagnant} / {this.state.data.rapportMoyGagnant}</span></TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left"><span class="lato-regular">Rapports placés touchés (min. / max. / moy.) :</span></TableCell>
              <TableCell><span class="lato-regular">{this.state.data.rapportMinPlace} / {this.state.data.rapportMaxPlace} / {this.state.data.rapportMoyPlace}</span></TableCell>
            </TableRow>
            <TableRow>
            <TableCell align="left"><span class="lato-regular">Rendement gagnant / placé (mise fixe)</span></TableCell>
            <TableCell align="left">
              <div style={{display:"flex"}}>
                <div>
                <span class="lato-regular">{rendement((this.state.data.rendementGagnant).toFixed(0))}</span>
                </div>
                <div style={{marginLeft:"0.5em"}}>
                <span class="lato-regular">{rendement((this.state.data.rendementPlace).toFixed(0))}</span>
                </div>
              </div>
              </TableCell>
            </TableRow>
           
        </TableBody>
      </Table>
      </div>
    )
  }
}