
/*
export const URL = "http://localhost:3000/";
export const URL_WS_CRITERES = "http://51.38.231.178:8083/criteres/categorie";
export const URL_WS_FILTRES = "http://51.38.231.178:8083/filtres";
export const URL_WS_FILTRE = "http://51.38.231.178:8083/filtre";
export const URL_WS_SELECTION_SAVE = "http://51.38.231.178:8083/selection/save";
export const URL_WS_CONDITION_SAVE = "http://51.38.231.178:8083/condition/save";
export const URL_WS_SELECTIONS = "http://51.38.231.178:8083/selections";
export const URL_WS_SELECTION = "http://51.38.231.178:8083/selection";
export const URL_WS_CONDITIONS = "http://51.38.231.178:8083/conditions";
export const URL_WS_CONDITION = "http://51.38.231.178:8083/condition";
export const URL_WS_PERIODES = "http://51.38.231.178:8083/periodes";
export const URL_WS_CONDITIONS_RECHERCHE = "http://51.38.231.178:8083/conditions/recherche";
export const URL_WS_SELECTIONS_RECHERCHE = "http://51.38.231.178:8083/selections/recherche";
export const URL_WS_SELECTION_DELETE = "http://51.38.231.178:8083/selection/delete";
export const URL_WS_CONDITION_DELETE = "http://51.38.231.178:8083/condition/delete";
export const URL_WS_CONDITION_DOSSIER = "http://51.38.231.178:8083/condition/dossier/update";
export const URL_WS_SELECTION_DOSSIER = "http://51.38.231.178:8083/selection/dossier/update";
export const URL_WS_METHODE_TEST = "http://51.38.231.178:8083/methode/exec";
export const URL_WS_MAINTENANCE = "http://51.38.231.178:8083/config/maintenance";
export const URL_WS_METHODE_SAVE = "http://51.38.231.178:8083/methode/save";
export const URL_WS_METHODE_EXPORT = "http://51.38.231.178:8083/methode/export";
export const URL_WS_METHODE_PRONOSTICS = "http://51.38.231.178:8083/methode/pronostics";
export const URL_WS_METHODE_DELETE = "http://51.38.231.178:8083/methode/pronostics/delete";
export const URL_WS_METHODE_EXEC = "http://51.38.231.178:8083/methode/exec/methode";
export const URL_WS_METHODE_ACTIF = "http://51.38.231.178:8083/methode/actif";
export const URL_WS_SYNTHESE = "http://51.38.231.178:8083/syntheses";
export const URL_WS_COURSE_JOUR = "http://51.38.231.178:8083/courses/jour";
export const URL_WS_FICHE_PARTANT = "http://51.38.231.178:8083/fiche";
export const URL_WS_FICHE_COURSE = "http://51.38.231.178:8083/fiche/course";
export const URL_WS_FILTRE_SAVE = "http://51.38.231.178:8083/filtre/save";
export const URL_WS_AUTH = "http://51.38.231.178:8083/auth";
export const URL_WS_DATES = "http://51.38.231.178:8083/dates";
*/


export const URL = "https://trotstats.fr/";
export const URL_WS_CRITERES = "https://trotstats.fr/ws/criteresCategorie.php";
export const URL_WS_FILTRES = "https://trotstats.fr/ws/filtres.php";
export const URL_WS_FILTRE = "https://trotstats.fr/ws/filtre.php";
export const URL_WS_SELECTION_SAVE = "https://trotstats.fr/ws/selectionSave.php";
export const URL_WS_CONDITION_SAVE = "https://trotstats.fr/ws/conditionSave.php";
export const URL_WS_SELECTIONS = "https://trotstats.fr/ws/selections.php";
export const URL_WS_SELECTION = "https://trotstats.fr/ws/selection.php";
export const URL_WS_CONDITIONS = "https://trotstats.fr/ws/conditions.php";
export const URL_WS_CONDITION = "https://trotstats.fr/ws/condition.php";
export const URL_WS_PERIODES = "https://trotstats.fr/ws/periodes.php";
export const URL_WS_CONDITIONS_RECHERCHE = "https://trotstats.fr/ws/conditionsRecherche.php";
export const URL_WS_SELECTIONS_RECHERCHE = "https://trotstats.fr/ws/selectionsRecherche.php";
export const URL_WS_SELECTION_DELETE = "https://trotstats.fr/ws/selectionDelete.php";
export const URL_WS_CONDITION_DELETE = "https://trotstats.fr/ws/conditionDelete.php";
export const URL_WS_CONDITION_DOSSIER = "https://trotstats.fr/ws/conditionDossier.php";
export const URL_WS_SELECTION_DOSSIER = "https://trotstats.fr/ws/selectionDossier.php";
export const URL_WS_METHODE_TEST = "https://trotstats.fr/ws/methodeTest.php";
export const URL_WS_MAINTENANCE = "https://trotstats.fr/ws/maintenance.php";
export const URL_WS_METHODE_SAVE = "https://trotstats.fr/ws/methodeSave.php";
export const URL_WS_METHODE_EXPORT = "https://trotstats.fr/ws/methodeExport.php";
export const URL_WS_METHODE_PRONOSTICS = "https://trotstats.fr/ws/methodePronostics.php";
export const URL_WS_METHODE_DELETE = "https://trotstats.fr/ws/methodeDelete.php";
export const URL_WS_METHODE_EXEC = "https://trotstats.fr/ws/methodeExec.php";
export const URL_WS_METHODE_ACTIF = "https://trotstats.fr/ws/methodeActif.php";
export const URL_WS_SYNTHESE = "https://trotstats.fr/ws/syntheses.php";
export const URL_WS_COURSE_JOUR = "https://trotstats.fr/ws/courseJour.php";
export const URL_WS_FICHE_PARTANT = "https://trotstats.fr/ws/fichePartant.php";
export const URL_WS_FICHE_COURSE = "https://trotstats.fr/ws/ficheCourse.php";
export const URL_WS_FILTRE_SAVE = "https://trotstats.fr/ws/saveFiltre.php";
export const URL_WS_AUTH = "https://trotstats.fr/ws/auth.php";
export const URL_WS_DATES = "https://trotstats.fr/ws/dates.php";



/*
export const URL = "http://localhost:3000/";
export const URL_WS_CRITERES = "http://localhost:8083/criteres/categorie";
export const URL_WS_FILTRES = "http://localhost:8083/filtres";
export const URL_WS_FILTRE = "http://localhost:8083/filtre";
export const URL_WS_SELECTION_SAVE = "http://localhost:8083/selection/save";
export const URL_WS_CONDITION_SAVE = "http://localhost:8083/condition/save";
export const URL_WS_SELECTIONS = "http://localhost:8083/selections";
export const URL_WS_SELECTION = "http://localhost:8083/selection";
export const URL_WS_CONDITIONS = "http://localhost:8083/conditions";
export const URL_WS_CONDITION = "http://localhost:8083/condition";
export const URL_WS_PERIODES = "http://localhost:8083/periodes";
export const URL_WS_CONDITIONS_RECHERCHE = "http://localhost:8083/conditions/recherche";
export const URL_WS_SELECTIONS_RECHERCHE = "http://localhost:8083/selections/recherche";
export const URL_WS_SELECTION_DELETE = "http://localhost:8083/selection/delete";
export const URL_WS_CONDITION_DELETE = "http://localhost:8083/condition/delete";
export const URL_WS_CONDITION_DOSSIER = "http://localhost:8083/condition/dossier/update";
export const URL_WS_SELECTION_DOSSIER = "http://localhost:8083/selection/dossier/update";
export const URL_WS_METHODE_TEST = "http://localhost:8083/methode/exec";
export const URL_WS_MAINTENANCE = "http://localhost:8083/config/maintenance";
export const URL_WS_METHODE_SAVE = "http://localhost:8083/methode/save";
export const URL_WS_METHODE_EXPORT = "http://localhost:8083/methode/export";
export const URL_WS_METHODE_PRONOSTICS = "http://localhost:8083/methode/pronostics";
export const URL_WS_METHODE_DELETE = "http://localhost:8083/methode/pronostics/delete";
export const URL_WS_METHODE_EXEC = "http://localhost:8083/methode/exec/methode";
export const URL_WS_METHODE_ACTIF = "http://localhost:8083/methode/actif";
export const URL_WS_SYNTHESE = "http://localhost:8083/syntheses";
export const URL_WS_COURSE_JOUR = "http://localhost:8083/courses/jour";
export const URL_WS_FICHE_PARTANT = "http://localhost:8083/fiche";
export const URL_WS_FICHE_COURSE = "http://localhost:8083/fiche/course";
export const URL_WS_FILTRE_SAVE = "http://localhost:8083/filtre/save";
export const URL_WS_AUTH = "http://localhost:8083/auth";
export const URL_WS_DATES = "http://localhost:8083/dates";
*/