import React, { Component } from "react";
import * as Const from "../Constant";
import Box from '@mui/material/Box';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Checkbox from '@mui/material/Checkbox';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ChoixSelection from "./ChoixSelection";
import ChoixCondition from "./ChoixCondition";
import ChoixPeriode from "./ChoixPeriode";
import Divider from '@mui/material/Divider';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PanelResultat from "./PanelResultat";
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import Drawer from '@mui/material/Drawer';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';
import { BlurCircular, QueuePlayNext, RotateRight } from "@mui/icons-material";
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';


export default class DialogAide extends Component {

    constructor(props) {
        super(props);
        this.state = {
         open:this.props.open,
         titre: this.props.titre,
         content: this.props.content
        }   
    }

    componentWillReceiveProps(props) {
    }

    componentDidMount() {
    
    }

    close= () => {
      this.props.close();
    }

    

   
    render() {
        return (
            <Dialog
              open={this.state.open}
              onClose={this.close}
              fullWidth
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
               {<span class="lato-bold">{this.state.titre}</span>}
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={this.close}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
              <CloseIcon style={{color:"black"}} />
              </IconButton>
              <Divider/>
              <DialogContent>
              <span class="lato-regular" style={{fontSize:"15px"}}>{this.state.content}</span>
                

              </DialogContent>
              <Divider/>
              <DialogActions>
              <Grid container spacing={2}>
                      
                <Grid item xs={6}>
                   
                </Grid>
                <Grid item xs={6}>
                  <IconButton
                      aria-label="close"
                      onClick={this.close}
                      style={{marginLeft:"1em", float:"right"}}
                      //onClick={}
                    >
                    <CheckOutlinedIcon style={{color:"black"}} />
                    </IconButton>
                </Grid>
              </Grid>
              
              </DialogActions>
            </Dialog>

            

        )
    }
}