import React, { Component } from "react";
import { DataGrid, GridRowsProp, GridColDef, GridLoadingOverlay } from '@mui/x-data-grid';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { DataGridPro } from '@mui/x-data-grid-pro';
import Paper from '@mui/material/Paper';
import { v4 as uuidv4 } from 'uuid';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';
import Button from '@mui/material/Button';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Grid from '@mui/material/Grid';
import { deepOrange, green } from '@mui/material/colors';
import { frFR } from '@mui/material/locale';


import Pagination from "@mui/material/Pagination";
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid";

export default class ChartLine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:this.props.data,
    };
    
  }

  componentWillReceiveProps(props) {
    this.setState({ data: props.data})
  }

  componentDidMount() {
  }

  /************************************************************************************
   * RENDU
   ***********************************************************************************/

  render() {

    
   
      return (
        <Card>
          <ResponsiveContainer width="100%" height={300}>
          <LineChart
          width={500}
          height={300}
          data={Array.from(this.state.data).reverse()}
          margin={{
            top: 20,
              right: 20,
              left: 20,
              bottom: 20,
          }}
        >
          <XAxis/>
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="soldeGagnant"  name={<span class="lato-regular !important">Solde Gagnant</span>} stroke="#3CA64A" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="soldePlace" name={<span class="lato-regular !important">Solde Placé</span>} stroke="#0089E9" activeDot={{ r: 8 }}/>
        </LineChart>
          </ResponsiveContainer>
          </Card>
        
      );

    
  }
}