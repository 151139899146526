import React, { Component } from "react";
import Box from '@mui/material/Box';
import * as Const from "../Constant";

import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Grid from '@mui/material/Grid';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import Paper from '@mui/material/Paper';
import Slider from '@mui/material/Slider';
import Alert from '@mui/material/Alert';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Snackbar from '@mui/material/Snackbar';
import Drawer from '@mui/material/Drawer';
import Dialog from '@mui/material/Dialog';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import DialogAide from './DialogAide';
import DialogAideProgrammeCourse from './DialogAideProgrammeCourse';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import ReadMoreOutlinedIcon from '@mui/icons-material/ReadMoreOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import OutlinedInput from '@mui/material/OutlinedInput';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';



export default class FiltreSynthese extends Component {
  constructor(props) {
    super(props);
    this.state = {
      utilisateur:this.props.utilisateur,
      dossier:"Pronostics Trotstats",
      courses: null,
      courseSelected: null,
      courseSelectedText: "-",
      aideCourse: false,
      aideCourseProgrammeCourse: false,
      groupedByReunion: null,
      openChoixCourse: false,
      dates: null,
      dateSelected: "Aujourd'hui",
      type:"citation"
    };
    
  }

  componentWillReceiveProps(props) {
    this.setState({ utilisateur: props.utilisateur})
  }

  componentDidMount() {
      this.callDates();
      //this.callCoursesJour();
  }

  handleCloseChoixCourse = () => {
    this.setState({ openChoixCourse: false})
  }

  handleOpenChoixCourse = () => {
    this.setState({ openChoixCourse: true})
  }

  handleSelectCourse = (course) => {
    this.setState({ courseSelectedText: "R" + course.reunion + " - " + course.hippodrome
      + " - C" + course.course + " - " + course.label
    })
    this.setState({ courseSelected: course }, () => {            
      this.props.handleCourseSelect(course, this.state.dossier );
      this.handleCloseChoixCourse()
    });
  }

  openDialogFicheCourse = () => {
    this.props.openDialogFicheCourse()
  }

  handleSelect = (event) => {
    this.setState({ dossier:event.target.value }, () => {            
      this.props.handleCourseSelect(this.state.courseSelected, event.target.value );
    });
  }

  handleSelectType = (event) => {
    this.setState({ type:event.target.value }, () => {            
      this.props.handleTypeSelect(this.state.type );
    });
  }

  callDates = () => {
    fetch(
      Const.URL_WS_DATES,
      { retry: 0, retryDelay: 1000 }
    )
      .then((res) => res.json())
      .then((data) => {
        this.setState({ dates: data }, () => {
          this.setState({ dateSelected: data[0]}, () => {
            this.callCoursesJour();  
          });
        });
      })
      .catch((error) => {
        console.log("Request failed", error);
      });
  }

  callCoursesJour = () => {
    this.setState({loader: false});
    fetch(
      Const.URL_WS_COURSE_JOUR + `?date=${this.state.dateSelected.valeur}`,
      { retry: 0, retryDelay: 1000 }
    )
      .then((res) => res.json())
      .then((data) => {
        this.setState({ groupedByReunion: data.groupedByReunion})
        this.setState({ courses: data.courses }, () => {
          if (this.state.courses && this.state.courses.length > 0) {
            this.setState({ courseSelected: this.state.courses[0] }, () => {            
              this.props.handleCourseSelect(this.state.courseSelected);
              this.setState({ courseSelectedText: "R" + this.state.courseSelected.reunion + " - " + this.state.courseSelected.hippodrome
                + " - C" + this.state.courseSelected.course + " - " + this.state.courseSelected.label
              })
            });
            this.props.message(false);
          }else{
            this.props.message(true);
          }
        });

        this.setState({loader: false});
      })
      .catch((error) => {
        console.log("Request failed", error);
        this.setState({loader: false});
      });
  }
  
handleDate = (event) => {
  this.setState({ dateSelected:event.target.value }, () => {            
    this.callCoursesJour();
  });
}

  aideProgrammeCourse = () => {
    this.setState({ aideCourseProgrammeCourse: true})
  }

  closeAideProgramme = () => {
    this.setState({ aideCourseProgrammeCourse: false})
  }

  


  /************************************************************************************
   * RENDU
   ***********************************************************************************/

  render() {

    return(
      <div>
    {this.state.aideCourseProgrammeCourse && (
    <DialogAideProgrammeCourse titre={"Programme des courses"} open={true} 
    close={this.closeAideProgramme}
    content="test"
    />
    )}

      <Card>
      <CardHeader
          avatar={
          <Avatar aria-label="recipe">
              P
          </Avatar>
          }
          action={
          <div>
              <HelpOutlineOutlinedIcon onClick={this.aideProgrammeCourse} style={{color:"black", margin:"0.3em", cursor:"pointer"}}/>
          </div>
          }
          title={<span class="lato-bold" style={{transform:"none", color:"black", fontSize:"15px"}}>Courses du jour</span>}
          subheader={<span class="lato-regular" style={{fontSize:"14px"}} >Le programme des courses</span>}
      />
      <CardContent>

      <FormControl fullWidth style={{  marginTop:"1em"}}>
                  <InputLabel id="demo-simple-select-label"><span style={{color:"black"}} class="lato-regular">Journée</span></InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.dateSelected}
                    label="Journée"
                    defaultValue={"Aujourd'hui"}
                    onChange={this.handleDate}
                  >

                    {this.state.dates != null && this.state.dates.map((date) => (
                        <MenuItem
                          value={date}
                        >
                          <span class="lato-regular"  style={{fontSize:"14px"}}>{date.label}</span>
                        </MenuItem>
                      ))}
                  
                  </Select>
        </FormControl>
       
        <InputLabel id="demo-simple-select-label"><span style={{color:"black", marginLeft:"1.0em", fontSize:"12px"}} class="lato-regular">Réunions et courses</span></InputLabel>
       
        <OutlinedInput
            type='text'
            size="small"
            onClick={() => this.handleOpenChoixCourse()}
           sx={{
              cursor:"pointer",
              fontSize:"13px",
              fontFamily: "Lato, sans-serif",
              fontWeight: "400",
              fontStyle: "normal"
            }}
             
            readOnly
            fullWidth
            value={this.state.courseSelectedText}
            endAdornment={
              <IconButton onClick={() => this.handleOpenChoixCourse()} aria-label="delete">

              <ReadMoreOutlinedIcon position="end">
                
              </ReadMoreOutlinedIcon>
              </IconButton>
            }
          />
     
     
      <FormControl fullWidth style={{ marginBottom:"1em", marginTop:"1em"}}>
                  <InputLabel id="demo-simple-select-label"><span style={{color:"black"}} class="lato-regular">Dossier Pronostics</span></InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.dossier}
                    label="Dossier Pronostics"
                    defaultValue={"Pronostics Trotstats"}
                    onChange={this.handleSelect}
                  >
                  <MenuItem value={"Pronostics Trotstats"}><span class="lato-regular" style={{fontSize:"14px"}}>Pronostics trotstats</span></MenuItem>
                  <MenuItem value={"Bases"}><span class="lato-regular" style={{fontSize:"14px"}}>Bases</span></MenuItem>
                  <MenuItem value={"Chances sérieuses"}><span class="lato-regular" style={{fontSize:"14px"}}>Chances sérieuses</span></MenuItem>
                  <MenuItem value={"A étudier"}><span class="lato-regular" style={{fontSize:"14px"}}>A étudier</span></MenuItem>
                  <MenuItem value={"Outsiders"}><span class="lato-regular" style={{fontSize:"14px"}}>Outsiders</span></MenuItem>
                  <MenuItem value={"Gros outsiders"}><span class="lato-regular" style={{fontSize:"14px"}}>Gros outsiders</span></MenuItem>
                  <MenuItem value={"Abandonnées"}><span class="lato-regular" style={{fontSize:"14px"}}>Abandonnées</span></MenuItem>
                  </Select>
        </FormControl>


        <FormControl fullWidth style={{ marginBottom:"1em", marginTop:"1em"}}>
                  <InputLabel id="demo-simple-select-label"><span style={{color:"black"}} class="lato-regular">Type Synthèse</span></InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.type}
                    label="Type Synthèse"
                    defaultValue={"citation"}
                    onChange={this.handleSelectType}
                  >
                  <MenuItem value={"citation"}><span class="lato-regular" style={{fontSize:"14px"}}>Nombre de citation</span></MenuItem>
                  <MenuItem value={"bonus"}><span class="lato-regular" style={{fontSize:"14px"}}>Bonus / Malus (points) </span></MenuItem>
                  
                  </Select>
        </FormControl>

       

      </CardContent>
      </Card>
      {/*
      <Card style={{marginTop:"1em"}}>
      <CardHeader
          avatar={
          <Avatar aria-label="recipe">
              T
          </Avatar>
          }
          action={
          <div>
         
              <HelpOutlineOutlinedIcon onClick={this.aideProgrammeCourse} style={{color:"black", margin:"0.3em", cursor:"pointer"}}/>
         
         
          </div>
          }
          title={<span class="lato-bold" style={{transform:"none", color:"black", fontSize:"15px"}}>Tableaux</span>}
          subheader={<span class="lato-regular" style={{fontSize:"14px"}} >Les tableaux d'informations</span>}
      />
      <CardContent>
        <Button fullWidth style={{textTransform:"none"}} onClick={this.openDialogFicheCourse} variant="outlined">
          <span class="lato-regular" style={{fontSize:"14px"}}>Fiche des partants</span>
        </Button>
      </CardContent>
      </Card>
     */}


      <Dialog
        open={this.state.openChoixCourse}
        onClose={this.handleCloseChoixCourse}
        fullWidth={true}
        maxWidth={"md"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
       
        <DialogContent>
       

        {this.state.groupedByReunion && Object.entries(this.state.groupedByReunion).map(([reunion, courses]) => (
            <Card fullWidth style={{marginBottom:"0.5em"}}>
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: "blue"}} aria-label="recipe">
                 <span>R{reunion}</span>
                </Avatar>
              }
              
              title={<span class="lato-bold">{courses[0].hippodrome}</span>}
              subheader={<span>{courses[0].region} - {courses[0].pisteVitesse} - {courses[0].typeVirage}</span>}
            />
           
            <CardContent>

            {courses.map((course) => (

            <Paper style={{cursor:"pointer", marginBottom:"1em"}} onClick={() => this.handleSelectCourse(course)}  elevation={3} >
              <div style={{height:"4em", display: "flex"}}>
                <div style={{margin:"0.5em", width:"70%"}}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ fontSize: "13px" }} className="lato-bold">
                    C{course.course} - {course.label}
                  </span>
                  <Tooltip title={<span style={{fontSize:"14px"}} className="lato-regular">{course.conditionCourse}</span>}>
                    <InfoOutlinedIcon style={{ width: "18px", marginLeft: "5px" }} />
                  </Tooltip>
                </div>
                    <div>
                    <span style={{fontSize:"13px"}} class="lato-regular">{course.heure} - {course.categorie} - {course.nombrePartant} partants - {course.discipline} - {course.distance} m - {course.montant} euro - {course.autostart}</span>
                    </div>
                </div>
              
              <div style={{margin:"0.5em", width:"30%"}}>
              <Grid container >
              <Grid item xs={4}>
              <Tooltip title={<span style={{fontSize:"14px"}} className="lato-regular">Indice du classement officiel des chevaux : plus la valeur est élevée, meilleure est la qualité des chevaux.</span>}>
                <Avatar style={{backgroundColor:"#155CD5", width:"60px", height:"25px"}} variant="square">
                  <span style={{fontSize:"13px"}} class="lato-regular">{course.crackMoy}</span>
                </Avatar>
                </Tooltip>
              </Grid>
              <Grid item xs={4}>
              <Tooltip title={<span style={{fontSize:"14px"}} className="lato-regular">Indice de forme des chevaux : plus la valeur est faible, meilleure est la performance récente des chevaux.</span>}>
              <Avatar style={{backgroundColor:"#155CD5", width:"60px", height:"25px"}} variant="square">
                <span style={{fontSize:"13px"}} class="lato-regular">{course.formeMoy}</span>
              </Avatar>
              </Tooltip>
              </Grid>
              <Grid item xs={4}>
                <Tooltip title={<span style={{fontSize:"14px"}} className="lato-regular">Indice de popularité des chevaux : plus la valeur est faible, plus les chevaux sont populaires auprès des parieurs.</span>}>
                <Avatar style={{backgroundColor:"#155CD5", width:"60px", height:"25px"}} variant="square">
                  <span style={{fontSize:"13px"}} class="lato-regular">{course.popMoy}</span>
                </Avatar>
                </Tooltip>
              </Grid>
              </Grid>
                  
                  
              </div>
              </div>
              
            </Paper>

              
            ))}

            </CardContent>
           
           
          </Card>
        ))}

        </DialogContent>
       
      </Dialog>

      </div>


    )
  }
}