import React, { Component } from "react";
import { DataGrid, GridRowsProp, GridColDef, GridLoadingOverlay } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Paper from '@mui/material/Paper';
import { v4 as uuidv4 } from 'uuid';
import Avatar from '@mui/material/Avatar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';
import Button from '@mui/material/Button';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Grid from '@mui/material/Grid';
import Pagination from "@mui/material/Pagination";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid";

export default class TableauEcart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:this.props.data,
    };
    
  }

  componentWillReceiveProps(props) {
    this.setState({ data: props.data})
  }

  componentDidMount() {
  }

  /************************************************************************************
   * RENDU
   ***********************************************************************************/

  render() {

    return(
      <div >
      <Table component={Paper}>
        <TableHead>
          <TableRow>
            <TableCell><span class="lato-bold">Informations sur les écarts</span></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          
            <TableRow>
              <TableCell width={"75%"} align="left"><span class="lato-regular">Écarts courses avec cheval gagnant (min. / max. / moy.) :</span></TableCell>
              <TableCell><span class="lato-regular">{this.state.data.analyse.ecartGagnantMin} / {this.state.data.analyse.ecartGagnantMax} / {this.state.data.analyse.ecartGagnantMoy}</span></TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left"><span class="lato-regular">Écarts courses avec cheval placé (min. / max. / moy.) :</span></TableCell>
              <TableCell><span class="lato-regular">{this.state.data.analyse.ecartPlaceMin} / {this.state.data.analyse.ecartPlaceMax} / {this.state.data.analyse.ecartPlaceMoy}</span></TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left"><span class="lato-regular">Écarts courses actuels (gagnant / placé) :</span></TableCell>
              <TableCell><span class="lato-regular">{this.state.data.analyse.ecartGagnantAct} / {this.state.data.analyse.ecartPlaceAct}</span></TableCell>
            </TableRow>
        </TableBody>
      </Table>
      </div>
    )
  }
}