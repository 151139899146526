import React, { Component } from "react";
import * as Const from "../Constant";
import Box from '@mui/material/Box';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Checkbox from '@mui/material/Checkbox';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ChoixSelection from "./ChoixSelection";
import ChoixCondition from "./ChoixCondition";
import ChoixPeriode from "./ChoixPeriode";
import Divider from '@mui/material/Divider';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PanelResultat from "./PanelResultat";
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import Drawer from '@mui/material/Drawer';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';
import { BlurCircular, QueuePlayNext, RotateRight } from "@mui/icons-material";
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';


export default class ZoneRechercheFiltre extends Component {

    constructor(props) {
        super(props);
        this.state = {  
          rechercheFiltre: this.props.rechercheFiltre,
        
        }   
    }

    componentWillReceiveProps(props) {
      this.setState({ rechercheFiltre: props.rechercheFiltre})
    }

    componentDidMount() {
    
    }

    close= () => {
      this.props.close();
    }

    keyPressFiltre = (e) => {
      if(e.keyCode == 13){
        this.props.handleZoneRechercheFiltre(this.state.rechercheFiltre);
      }
   }

   
    render() {
        return (
            
          <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: <SearchOutlinedIcon/>,
                    className: "lato-regular",
                  }}
                  id="outlined-controlled"
                  onKeyDown={this.keyPressFiltre}
                  autoComplete={"off"}
                  size="small"
                  label={<span style={{color:"black"}} class="lato-regular">Recherche</span>}
                  value={this.state.rechercheFiltre}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({rechercheFiltre: event.target.value});
                  }}
                />

            

        )
    }
}