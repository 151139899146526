import React, { Component } from "react";
import { DataGrid, GridRowsProp, GridColDef, GridLoadingOverlay } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Paper from '@mui/material/Paper';
import { v4 as uuidv4 } from 'uuid';
import Avatar from '@mui/material/Avatar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';
import Button from '@mui/material/Button';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Grid from '@mui/material/Grid';
import Pagination from "@mui/material/Pagination";
import * as Const from "../Constant";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Tooltip from '@mui/material/Tooltip';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Alert from '@mui/material/Alert';
import DialogResultat from './DialogResultat';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import DialogAide from "./DialogAide";
import DialogFiche from "./DialogFiche";
import AlertTitle from '@mui/material/AlertTitle';


import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid";

export default class SyntheseResultatPoints extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:this.props.data,
      loader: false,
      openDialog: false,
      result:null,
      aideNumero: false,
      aidePerf: false,
      aideSynthese: false,
      dataFiche: null,
      openFiche: false
    };
    
  }

  aideSynthese = () => {
    this.setState({ aideSynthese: true})
  }

  aideNumero = () => {
    this.setState({ aideNumero: true})
  }

  aidePerf = () => {
    this.setState({ aidePerf: true})
  }

  closeAideNumero = () => {
    this.setState({ aideNumero: false})
  }

  closeAideSynthese = () => {
    this.setState({ aideSynthese: false})
  }

  closeAidePerf = () => {
    this.setState({ aidePerf: false})
  }

  componentWillReceiveProps(props) {
    this.setState({ data: props.data})
  }

  componentDidMount() {

  }

  closeDialog = () => {
    this.setState({openDialog: false});
  }

  closeFiche = () => {
    this.setState({openFiche: false});
  }

  callFiche = (idCourse, numPmu) => {
    this.setState({loader: true});
    
    fetch(
      Const.URL_WS_FICHE_PARTANT + `?utilisateur=${localStorage.getItem("utilisateur_dtf")}&password=PASS&idCourse=${idCourse}&numPmu=${numPmu}`,
      { retry: 0, retryDelay: 1000 }
    )
      .then((res) => res.json())
      .then((data) => {
        this.setState({loader: false});
        this.setState({dataFiche: data});
        this.setState({openFiche: true});
      })
      .catch((error) => {
        console.log("Request failed", error);
        this.setState({loader: false});
      });

  }

  select = (id) => {
    this.setState({loader: true});
    fetch(
      Const.URL_WS_METHODE_EXEC + `?utilisateur=${localStorage.getItem("utilisateur_dtf")}&methodeID=${id}`,
      { retry: 0, retryDelay: 1000 }
    )
      .then((res) => res.json())
      .then((data) => {
        this.setState({loader: false});
        this.setState({result: data});
        this.setState({openDialog: true});
      })
      .catch((error) => {
        console.log("Request failed", error);
        this.setState({loader: false});
      });
  }

  /************************************************************************************
   * RENDU
   ***********************************************************************************/

  render() {

    function reussite(data){
      var color = "";

        if(data < 0){
          data = "-";
        }else if(data > 75){
          color = "#2D7E43";
        }else if(data > 50 && data <= 75){
          color = "#97BA38";
        }else if(data >= 25 && data <= 50){
          color = "#E8B610";
        }else if(data > 10 && data <= 25){
          color = "#D57B1A";
        }else if(data >= 0 && data <= 10){
          color = "#C53419";
        }

        return  (
          <Avatar style={{backgroundColor:color, width:"70px", height:"30px"}} variant="square">
            <span class="lato-regular" style={{ fontSize:"17px", color:"white"}}>{data} %</span>
          </Avatar>
        )
      
    }

    function point(data){
      var color = "";

        if(data >= 1){
          color = "#2D7E43";
          data = data.toString()
          data = "+ " + data;
        }else if(data < 0){
          color = "#C53419";
          data = data.toString().replace("-", "");
          data = "- " + data;
        }

        return  (
          <Avatar style={{backgroundColor:color, width:"70px", height:"30px"}} variant="square">
          <span class="lato-regular" style={{ fontSize:"17px", color:"white"}}>{data}</span>
        </Avatar>
        )
      
    }

    function color(num, syn1, syn2, syn3, syn4, syn5){
      if(num == syn1){
        return "#66B230"
      }

      if(num == syn2){
        return "#FF8F60"
      }

      if(num == syn3){
        return "#FFAA16"
      }

      if(num == syn4){
        return "#5B86F2"
      }

      if(num == syn5){
        return "#B491FF"
      }
      
      
      return "";
    }

    return(
      <div >

        <DialogFiche open={this.state.openFiche} closeFiche={this.closeFiche} data={this.state.dataFiche}/>

    {this.state.aideNumero && (
    <DialogAide titre={"Astuce"} open={true} close={this.closeAideNumero}
    content="Liste des numéros PMU des partants de la course, sélectionnés par les pronostics."
    />
    )}

    {this.state.aidePerf && (
    <DialogAide titre={"Astuce"} open={true} close={this.closeAidePerf}
    content="Indique les bonus / malus attribués aux pronostics."
    />
    )}

    {this.state.aideSynthese && (
    <DialogAide titre={"Astuce"} open={true} close={this.closeAideSynthese}
    content={<span>La <i>Synthèse</i> vous permet d'obtenir les chevaux les plus cités dans l'ensemble des pronostics disponibles pour la course sélectionnée.
      <br></br><br></br>Concentrez-vous sur deux ou trois chevaux pour faciliter la création de votre jeu.</span>}
    />
    )}
      
      <Card>
      <CardHeader
          avatar={
          <Avatar aria-label="recipe">
              C{this.state.data.bloc.course.numOrdre}
          </Avatar>
          }
          action={
          <div>
          </div>
          }
          title={<span class="lato-bold" style={{transform:"none", color:"black", fontSize:"13px"}}>{this.state.data.bloc.course.libelle}</span>}
          subheader={<span class="lato-regular" style={{fontSize:"14px"}} >{this.state.data.bloc.course.nombreDeclaresPartants} partants - {this.state.data.bloc.course.discipline} - {this.state.data.bloc.course.distance} m - {this.state.data.bloc.course.montantPrix} euro</span>}
      />
      <CardContent>
          <Card>
            <CardHeader
              title={
              
                <div style={{display:"flex"}}>
                <div>
                <span class="lato-bold" style={{transform:"none", color:"black", fontSize:"15px"}}>La Synthèse</span>
                </div>
                <div>
                <HelpOutlineOutlinedIcon style={{cursor:"pointer", marginLeft:"0.5em", marginTop:"0.2em"}} onClick={this.aideSynthese} />
                </div>
              </div>
              }
              
            />
            <CardContent>
            {this.state.data.bloc.synthese.syntheseParPoints.length == 0 && (
              <Alert severity="info"><span class="lato-regular">Synthèse non disponible</span>.</Alert>
            )}
            {this.state.data.bloc.synthese.syntheseParPoints.length > 0 && (
            <Table component={Paper}>
              <TableRow>
                <TableCell style={{width:"10%"}} align="left">
                  <div>
                    <span class="lato-bold" style={{fontSize:"14px"}}>Chevaux</span>
                  </div>
                  <div>
                    <span class="lato-bold-italic" style={{fontSize:"14px", color:'#1876D2'}}>Cumul points</span>
                  </div>
                  
                </TableCell>
                <TableCell style={{width:"90%"}} align="left">
                <div style={{display:"flex"}}>
              
                    {this.state.data.bloc.synthese.syntheseParPoints[0] != null && (
                      <div style={{marginLeft:"1em"}}>
                        <Badge badgeContent={this.state.data.bloc.synthese.syntheseParPoints[0].points} color="primary">
                         <Avatar sx={{ bgcolor: "#66B230", cursor:"pointer"}} onClick={() => this.callFiche(this.state.data.bloc.course.id, this.state.data.bloc.synthese.syntheseParPoints[0].num)} >{this.state.data.bloc.synthese.syntheseParPoints[0].num}</Avatar>
                        </Badge>
                      </div>
                    )}
        
                    {this.state.data.bloc.synthese.syntheseParPoints[1] != null && (
                      <div style={{marginLeft:"1em"}}>
                        <Badge badgeContent={this.state.data.bloc.synthese.syntheseParPoints[1].points} color="primary">
                        <Avatar sx={{bgcolor: "#FF8F60", cursor:"pointer" }} onClick={() => this.callFiche(this.state.data.bloc.course.id, this.state.data.bloc.synthese.syntheseParPoints[1].num)}>{this.state.data.bloc.synthese.syntheseParPoints[1].num}</Avatar>
                        </Badge>
                      </div>
                    )}
        
                    {this.state.data.bloc.synthese.syntheseParPoints[2] != null && (
                      <div style={{marginLeft:"1em"}}>
                        <Badge badgeContent={this.state.data.bloc.synthese.syntheseParPoints[2].points} color="primary">
                        <Avatar sx={{bgcolor: "#FFAA16", cursor:"pointer" }} onClick={() => this.callFiche(this.state.data.bloc.course.id, this.state.data.bloc.synthese.syntheseParPoints[2].num)}>{this.state.data.bloc.synthese.syntheseParPoints[2].num}</Avatar>
                        </Badge>
                      </div>
                    )}
        
                    {this.state.data.bloc.synthese.syntheseParPoints[3] != null && (
                      <div style={{marginLeft:"1em"}}>
                        <Badge badgeContent={this.state.data.bloc.synthese.syntheseParPoints[3].points} color="primary">
                        <Avatar sx={{bgcolor: "#5B86F2", cursor:"pointer" }} onClick={() => this.callFiche(this.state.data.bloc.course.id, this.state.data.bloc.synthese.syntheseParPoints[3].num)}>{this.state.data.bloc.synthese.syntheseParPoints[3].num}</Avatar>
                        </Badge>
                      </div>
                    )}
        
                    {this.state.data.bloc.synthese.syntheseParPoints[4] != null && (
                      <div style={{marginLeft:"1em"}}>
                        <Badge badgeContent={this.state.data.bloc.synthese.syntheseParPoints[4].points} color="primary">
                        <Avatar sx={{bgcolor: "#B491FF", cursor:"pointer" }} onClick={() => this.callFiche(this.state.data.bloc.course.id, this.state.data.bloc.synthese.syntheseParPoints[4].num)}>{this.state.data.bloc.synthese.syntheseParPoints[4].num}</Avatar>
                        </Badge>
                      </div>
                    )}
                    </div>
                </TableCell>
                </TableRow>
            </Table>
            )}

            </CardContent>
          </Card>


        {/*<Accordion style={{marginTop:"1em"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div>
            <span class="lato-bold" style={{transform:"none", color:"black", fontSize:"15px"}}>Les Partants</span>
          </div>
          <div>
            <span class="lato-regular-italic" style={{fontSize:"14px", marginLeft:"1em"}}></span>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {this.state.data.partants != null && this.state.data.partants.map((partant) => (
              <Card style={{marginTop:"1em"}}>
                <CardContent>
                  <Table component={Paper}>
                    
                    <TableRow>
                      <TableCell style={{width:"40%"}}>
                        <div style={{display:"flex"}}>
                          <div>
                            <Avatar sx={{ bgcolor: color(partant.partant.numPmu, this.state.data.bloc.synthese.synthese[0] != null ? this.state.data.bloc.synthese.synthese[0].num : "", this.state.data.bloc.synthese.synthese[1] != null ? this.state.data.bloc.synthese.synthese[1].num : "")}}>
                              <span class="lato-regular" style={{fontSize:"14px", cursor:"pointer"}}>{partant.partant.numPmu}</span>
                            </Avatar>
                          </div>
                          <div style={{marginLeft:"1em"}}>  
                            <span class="lato-bold" style={{fontSize:"14px", cursor:"pointer"}}>{partant.partant.nom}</span>
                            <span class="lato-regular" style={{fontSize:"12px", marginLeft:"0.5em"}}>({partant.partant.sexe}{partant.partant.age})</span>
                            <span class="lato-regular" style={{fontSize:"12px", marginLeft:"0.5em"}}>{partant.partant.deferre}</span>
                            <div>
                              <span class="lato-regular" style={{fontSize:"12px", cursor:"pointer"}}>{partant.partant.driver}</span> / <span class="lato-regular" style={{fontSize:"12px", cursor:"pointer"}}>{partant.partant.entraineur}</span>
                            </div>
                          </div>
                        </div>
                      </TableCell>

                      
                      <TableCell style={{width:"30%"}}>
                        <div>
                        <span class="lato-regular" style={{fontSize:"14px"}}>{partant.partant.musique}</span>
                        </div>
                        <div>
                        <span class="lato-regular-italic" style={{fontSize:"14px"}}>{partant.partant.handicapDistance} m / {partant.partant.gainsCarriere} euro</span>
                        </div>
                      </TableCell>

                      <TableCell style={{width:"30%"}}>
                        <div style={{float:"right"}}>
                        <span class="lato-regular" style={{fontSize:"14px"}}>{partant.partant.musique}</span>
                        </div>
                      </TableCell>
                    </TableRow>
                  </Table>
                </CardContent>
              </Card>
            ))}
        </AccordionDetails>
      </Accordion>*/}


          <Card style={{marginTop:"1em"}}>
          <CardHeader
              title={<span class="lato-bold" style={{transform:"none", color:"black", fontSize:"15px"}}>Les Pronostics</span>}
            />
            <CardContent>

            {this.state.data.bloc.pronostics == null || this.state.data.bloc.pronostics.length == 0 && (
              <Alert severity="info">
                <AlertTitle><span class="lato-regular" style={{transform:"none", fontSize:"14px", textTransform:"none"}}>Information</span></AlertTitle>
                <span class="lato-regular">Aucun pronostic trouvé pour cette course.</span></Alert>
            )}

            <Table component={Paper}>
                <TableRow>
                <TableCell style={{width:"50%"}} align="left">
            <span  class="lato-bold">
              #
            </span>
            </TableCell>
            <TableCell style={{width:"40%"}} align="left">
            <div style={{display:"flex"}}>
              <div>
              <span  class="lato-bold">
              Numéros 
               </span>
              </div>
              <div>
              <HelpOutlineOutlinedIcon style={{cursor:"pointer", marginLeft:"0.5em", marginTop:"-0.1em"}} onClick={this.aideNumero} />
              </div>
            </div>
            
            </TableCell>
            <TableCell style={{width:"10%"}} align="left">
            <div style={{display:"flex"}}>
              <div>
              <span  class="lato-bold">
              Points
               </span>
              </div>
              <div>
              <HelpOutlineOutlinedIcon style={{cursor:"pointer", marginLeft:"0.5em", marginTop:"-0.1em"}} onClick={this.aidePerf} />
              </div>
            </div>
            </TableCell>

            </TableRow>
            </Table>
            

            {this.state.data.bloc.pronostics != null && this.state.data.bloc.pronostics.map((prono) => (
          
              
              <Card style={{marginTop:"1em"}}>
                

                <Table component={Paper}>
                <TableRow>
                <TableCell style={{width:"50%"}} align="left">
                  <div onClick={() => this.select(prono.methode.id)} style={{cursor:"pointer"}}>
                  
                  
                  <IconButton onClick={() => this.select(prono.methode.id)} size="small" style={{textTransform:"none", marginRight:"0.5em"}} >
                    <ArticleOutlinedIcon fontSize="small" size="small"/>
                  </IconButton>
                   
                      <span class="lato-bold" style={{fontSize:"14px"}}>{prono.methode.label}</span>
                   
                  </div>
                  <div onClick={() => this.select(prono.methode.id)} style={{cursor:"pointer"}}>
                    <span class="lato-regular-italic" style={{fontSize:"14px"}}>{prono.methode.description}</span>
                  </div>
                  
                </TableCell>

                

                <TableCell style={{width:"40%"}} align="left">
                <div style={{display:"flex"}}>
                  {prono.pronostics[0].p1 != -1  && (
                    <Tooltip title={<span class="lato-regular">{prono.pronostics[0].cheval1}</span>}>
                    <Avatar sx={{ cursor:"pointer", bgcolor: color(prono.pronostics[0].p1, this.state.data.bloc.synthese.syntheseParPoints[0] != null ? this.state.data.bloc.synthese.syntheseParPoints[0].num : "", this.state.data.bloc.synthese.syntheseParPoints[1] != null ? this.state.data.bloc.synthese.syntheseParPoints[1].num : "", this.state.data.bloc.synthese.syntheseParPoints[2] != null ? this.state.data.bloc.synthese.syntheseParPoints[2].num : "", this.state.data.bloc.synthese.syntheseParPoints[3] != null ? this.state.data.bloc.synthese.syntheseParPoints[3].num : "", this.state.data.bloc.synthese.syntheseParPoints[4] != null ? this.state.data.bloc.synthese.syntheseParPoints[4].num : "")}}>{prono.pronostics[0].p1}</Avatar>
                    </Tooltip>
                  )}
                  {prono.pronostics[0].p2 != -1 && (
                    <Tooltip title={<span class="lato-regular">{prono.pronostics[0].cheval2}</span>}>
                    <Avatar sx={{ cursor:"pointer", bgcolor: color(prono.pronostics[0].p2, this.state.data.bloc.synthese.syntheseParPoints[0] != null ? this.state.data.bloc.synthese.syntheseParPoints[0].num : "", this.state.data.bloc.synthese.syntheseParPoints[1] != null ? this.state.data.bloc.synthese.syntheseParPoints[1].num : "", this.state.data.bloc.synthese.syntheseParPoints[2] != null ? this.state.data.bloc.synthese.syntheseParPoints[2].num : "", this.state.data.bloc.synthese.syntheseParPoints[3] != null ? this.state.data.bloc.synthese.syntheseParPoints[3].num : "", this.state.data.bloc.synthese.syntheseParPoints[4] != null ? this.state.data.bloc.synthese.syntheseParPoints[4].num : "")}} style={{marginLeft:"1em"}}>{prono.pronostics[0].p2}</Avatar>
                    </Tooltip>
                  )}
                  {prono.pronostics[0].p3 != -1  && (
                    <Tooltip title={<span class="lato-regular">{prono.pronostics[0].cheval3}</span>}>
                    <Avatar sx={{ cursor:"pointer", bgcolor: color(prono.pronostics[0].p3, this.state.data.bloc.synthese.syntheseParPoints[0] != null ? this.state.data.bloc.synthese.syntheseParPoints[0].num : "", this.state.data.bloc.synthese.syntheseParPoints[1] != null ? this.state.data.bloc.synthese.syntheseParPoints[1].num : "", this.state.data.bloc.synthese.syntheseParPoints[2] != null ? this.state.data.bloc.synthese.syntheseParPoints[2].num : "", this.state.data.bloc.synthese.syntheseParPoints[3] != null ? this.state.data.bloc.synthese.syntheseParPoints[3].num : "", this.state.data.bloc.synthese.syntheseParPoints[4] != null ? this.state.data.bloc.synthese.syntheseParPoints[4].num : "")}} style={{marginLeft:"1em"}}>{prono.pronostics[0].p3}</Avatar>
                    </Tooltip>
                  )}
                  {prono.pronostics[0].p4 != -1  && (
                    <Tooltip title={<span class="lato-regular">{prono.pronostics[0].cheval4}</span>}>
                    <Avatar sx={{ cursor:"pointer", bgcolor: color(prono.pronostics[0].p4, this.state.data.bloc.synthese.syntheseParPoints[0] != null ? this.state.data.bloc.synthese.syntheseParPoints[0].num : "", this.state.data.bloc.synthese.syntheseParPoints[1] != null ? this.state.data.bloc.synthese.syntheseParPoints[1].num : "", this.state.data.bloc.synthese.syntheseParPoints[2] != null ? this.state.data.bloc.synthese.syntheseParPoints[2].num : "", this.state.data.bloc.synthese.syntheseParPoints[3] != null ? this.state.data.bloc.synthese.syntheseParPoints[3].num : "", this.state.data.bloc.synthese.syntheseParPoints[4] != null ? this.state.data.bloc.synthese.syntheseParPoints[4].num : "")}} style={{marginLeft:"1em"}}>{prono.pronostics[0].p4}</Avatar>
                    </Tooltip>
                  )}
                  {prono.pronostics[0].p5 != -1  && (
                    <Tooltip title={<span class="lato-regular">{prono.pronostics[0].cheval5}</span>}>
                    <Avatar sx={{ cursor:"pointer", bgcolor: color(prono.pronostics[0].p5, this.state.data.bloc.synthese.syntheseParPoints[0] != null ? this.state.data.bloc.synthese.syntheseParPoints[0].num : "", this.state.data.bloc.synthese.syntheseParPoints[1] != null ? this.state.data.bloc.synthese.syntheseParPoints[1].num : "", this.state.data.bloc.synthese.syntheseParPoints[2] != null ? this.state.data.bloc.synthese.syntheseParPoints[2].num : "", this.state.data.bloc.synthese.syntheseParPoints[3] != null ? this.state.data.bloc.synthese.syntheseParPoints[3].num : "", this.state.data.bloc.synthese.syntheseParPoints[4] != null ? this.state.data.bloc.synthese.syntheseParPoints[4].num : "")}} style={{marginLeft:"1em"}}>{prono.pronostics[0].p5}</Avatar>
                    </Tooltip>
                  )}
                  {prono.pronostics[0].p6 != -1  && (
                    <Tooltip title={<span class="lato-regular">{prono.pronostics[0].cheval6}</span>}>
                    <Avatar sx={{ cursor:"pointer", bgcolor: color(prono.pronostics[0].p6, this.state.data.bloc.synthese.syntheseParPoints[0] != null ? this.state.data.bloc.synthese.syntheseParPoints[0].num : "", this.state.data.bloc.synthese.syntheseParPoints[1] != null ? this.state.data.bloc.synthese.syntheseParPoints[1].num : "", this.state.data.bloc.synthese.syntheseParPoints[2] != null ? this.state.data.bloc.synthese.syntheseParPoints[2].num : "", this.state.data.bloc.synthese.syntheseParPoints[3] != null ? this.state.data.bloc.synthese.syntheseParPoints[3].num : "", this.state.data.bloc.synthese.syntheseParPoints[4] != null ? this.state.data.bloc.synthese.syntheseParPoints[4].num : "")}} style={{marginLeft:"1em"}}>{prono.pronostics[0].p6}</Avatar>
                    </Tooltip>
                  )}
                  {prono.pronostics[0].p7 != -1  && (
                    <Tooltip title={<span class="lato-regular">{prono.pronostics[0].cheval7}</span>}>
                    <Avatar sx={{ cursor:"pointer", bgcolor: color(prono.pronostics[0].p7, this.state.data.bloc.synthese.syntheseParPoints[0] != null ? this.state.data.bloc.synthese.syntheseParPoints[0].num : "", this.state.data.bloc.synthese.syntheseParPoints[1] != null ? this.state.data.bloc.synthese.syntheseParPoints[1].num : "", this.state.data.bloc.synthese.syntheseParPoints[2] != null ? this.state.data.bloc.synthese.syntheseParPoints[2].num : "", this.state.data.bloc.synthese.syntheseParPoints[3] != null ? this.state.data.bloc.synthese.syntheseParPoints[3].num : "", this.state.data.bloc.synthese.syntheseParPoints[4] != null ? this.state.data.bloc.synthese.syntheseParPoints[4].num : "")}} style={{marginLeft:"1em"}}>{prono.pronostics[0].p7}</Avatar>
                    </Tooltip>
                  )}
                  {prono.pronostics[0].p8 != -1 && (
                    <Tooltip title={<span class="lato-regular">{prono.pronostics[0].cheval8}</span>}>
                    <Avatar sx={{ cursor:"pointer", bgcolor: color(prono.pronostics[0].p8, this.state.data.bloc.synthese.syntheseParPoints[0] != null ? this.state.data.bloc.synthese.syntheseParPoints[0].num : "", this.state.data.bloc.synthese.syntheseParPoints[1] != null ? this.state.data.bloc.synthese.syntheseParPoints[1].num : "", this.state.data.bloc.synthese.syntheseParPoints[2] != null ? this.state.data.bloc.synthese.syntheseParPoints[2].num : "", this.state.data.bloc.synthese.syntheseParPoints[3] != null ? this.state.data.bloc.synthese.syntheseParPoints[3].num : "", this.state.data.bloc.synthese.syntheseParPoints[4] != null ? this.state.data.bloc.synthese.syntheseParPoints[4].num : "")}} style={{marginLeft:"1em"}}>{prono.pronostics[0].p8}</Avatar>
                    </Tooltip>
                  )}
                  </div>
                </TableCell>

                <TableCell style={{width:"10%"}} align="left">
                  {point(prono.methode.point)}
                </TableCell>


                </TableRow>
                </Table>
              </Card>

              ))}



     {/*<Accordion style={{marginTop:"1em"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          
          <div>
            <span class="lato-regular-italic" style={{fontSize:"14px", marginLeft:"1em"}}>Non comptabilisés pour la synthèse</span>
          </div>
        </AccordionSummary>
        <AccordionDetails>
        {this.state.data.bloc.pronosticsHorsSynthese == null || this.state.data.bloc.pronosticsHorsSynthese.length == 0 && (
          <Alert severity="info"><span class="lato-regular">Aucun pronostics trouvés</span>.</Alert>
        )}

        {this.state.data.bloc.pronosticsHorsSynthese != null && this.state.data.bloc.pronosticsHorsSynthese.map((prono) => (
          
          
          <Card style={{marginTop:"1em"}}>
            <Table component={Paper}>
            <TableRow>
            <TableCell style={{width:"50%"}} align="left">
              <div onClick={() => this.select(prono.methode.id)} style={{cursor:"pointer"}}>
              <IconButton onClick={() => this.select(prono.methode.id)} size="small" style={{textTransform:"none", marginRight:"0.5em"}} >
                    <ArticleOutlinedIcon fontSize="small" size="small"/>
                  </IconButton>

                <span class="lato-bold" style={{fontSize:"14px"}}>{prono.methode.label}</span>
             
              </div>
              <div onClick={() => this.select(prono.methode.id)} style={{cursor:"pointer"}}>
                <span class="lato-regular-italic" style={{fontSize:"14px"}}>{prono.methode.description}</span>
              </div>
              
            </TableCell>

            <TableCell style={{width:"50%"}} align="left">
            <div style={{display:"flex"}}>
              {prono.pronostics[0].p1 != -1 && (
                <Tooltip title={<span class="lato-regular">{prono.pronostics[0].cheval1}</span>}>
                <Avatar>{prono.pronostics[0].p1}</Avatar>
                </Tooltip>
              )}
              {prono.pronostics[0].p2 != -1 && (
                <Tooltip title={<span class="lato-regular">{prono.pronostics[0].cheval2}</span>}>
                <Avatar style={{marginLeft:"1em"}}>{prono.pronostics[0].p2}</Avatar>
                </Tooltip>
              )}
              {prono.pronostics[0].p3 != -1 && (
                <Tooltip title={<span class="lato-regular">{prono.pronostics[0].cheval3}</span>}>
                <Avatar style={{marginLeft:"1em"}}>{prono.pronostics[0].p3}</Avatar>
                </Tooltip>
              )}
              {prono.pronostics[0].p4 != -1 && (
                <Tooltip title={<span class="lato-regular">{prono.pronostics[0].cheval4}</span>}>
                <Avatar style={{marginLeft:"1em"}}>{prono.pronostics[0].p4}</Avatar>
                </Tooltip>
              )}
              {prono.pronostics[0].p5 != -1 && (
                <Tooltip title={<span class="lato-regular">{prono.pronostics[0].cheval5}</span>}>
                <Avatar style={{marginLeft:"1em"}}>{prono.pronostics[0].p5}</Avatar>
                </Tooltip>
              )}
              {prono.pronostics[0].p6 != -1 && (
                <Tooltip title={<span class="lato-regular">{prono.pronostics[0].cheval6}</span>}>
                <Avatar style={{marginLeft:"1em"}}>{prono.pronostics[0].p6}</Avatar>
                </Tooltip>
              )}
              {prono.pronostics[0].p7 != -1 && (
                <Tooltip title={<span class="lato-regular">{prono.pronostics[0].cheval7}</span>}>
                <Avatar style={{marginLeft:"1em"}}>{prono.pronostics[0].p7}</Avatar>
                </Tooltip>
              )}
              {prono.pronostics[0].p8 != -1 && (
                <Tooltip title={<span class="lato-regular">{prono.pronostics[0].cheval8}</span>}>
                <Avatar style={{marginLeft:"1em"}}>{prono.pronostics[0].p8}</Avatar>
                </Tooltip>
              )}
              </div>
            </TableCell>

            </TableRow>
            </Table>
          </Card>

          ))}
        </AccordionDetails>
      </Accordion>*/}

            </CardContent>

          </Card>

          

      </CardContent>
      </Card>

      <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={this.state.loader}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
      
      <DialogResultat data={this.state.result} open={this.state.openDialog} closeDialog={this.closeDialog} />

      </div>
    )
  }
}