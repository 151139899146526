import React, { Component } from "react";
import * as Const from "../Constant";
import Box from '@mui/material/Box';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Checkbox from '@mui/material/Checkbox';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ChoixSelection from "./ChoixSelection";
import ChoixCondition from "./ChoixCondition";
import ChoixPeriode from "./ChoixPeriode";
import Divider from '@mui/material/Divider';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PanelResultat from "./PanelResultat";
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import Drawer from '@mui/material/Drawer';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';
import { BlurCircular, QueuePlayNext, RotateRight } from "@mui/icons-material";
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


export default class DialogFiche extends Component {

    constructor(props) {
        super(props);
        this.state = {
         open:this.props.open,
         data: this.props.data,
        
        }   
    }

    componentWillReceiveProps(props) {
      this.setState({ open: props.open})
      this.setState({ data: props.data})
    }

    componentDidMount() {
     
    }

    close= () => {
      this.props.closeFiche()
    }

 

    render() {
        return (
          
            <Dialog
              open={this.state.open}
              onClose={this.close}
              scroll={"body"}
              fullWidth
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
               
                {(this.state.data != null &&
               
                    <span class="lato-bold">Fiche</span>
               
                )}

              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={this.close}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
              <CloseIcon style={{color:"black"}} />
              </IconButton>
              <Divider/>
              <DialogContent>
              <Box sx={{ width: '100%' }}>
              {(this.state.data != null &&
              <div>
              <Table component={Paper}>
                <TableHead>
                  <TableRow>
                    <TableCell><span class="lato-bold">Trotteur - {this.state.data.partant.nom}</span></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                      <TableCell align="left"><span class="lato-regular">Nombre course ( 3 mois ) :</span></TableCell>
                      <TableCell align="right"><span class="lato-regular">{this.state.data.statistiqueTrotteur.nbCourse}</span></TableCell>
                    </TableRow>
                    
                    <TableRow>
                      <TableCell align="left"><span class="lato-regular">Forme :</span></TableCell>
                      <TableCell align="right"><span class="lato-regular">{this.state.data.indicateurTrotteur.forme5}</span></TableCell>
                    </TableRow>
                    
                    <TableRow>
                      <TableCell align="left"><span class="lato-regular">Forme récente :</span></TableCell>
                      <TableCell align="right"><span class="lato-regular">{this.state.data.indicateurTrotteur.forme3}</span></TableCell>
                    </TableRow>
                    
                    <TableRow>
                      <TableCell align="left"><span class="lato-regular">Progression forme :</span></TableCell>
                      <TableCell align="right"><span class="lato-regular">{this.state.data.indicateurTrotteur.progressionForme}</span></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left"><span class="lato-regular">Aptitude sur l'hippodrome :</span></TableCell>
                      <TableCell align="right"><span class="lato-regular">{this.state.data.indicateurTrotteur.ipgHippodrome}</span></TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left"><span class="lato-regular">Aptitude sur la distance :</span></TableCell>
                      <TableCell align="right"><span class="lato-regular">{this.state.data.indicateurTrotteur.ipgDistance}</span></TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left"><span class="lato-regular">Aptitude avec le driver :</span></TableCell>
                      <TableCell align="right"><span class="lato-regular">{this.state.data.indicateurTrotteur.ipgDriver}</span></TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left"><span class="lato-regular">Aptitude avec l'entraineur :</span></TableCell>
                      <TableCell align="right"><span class="lato-regular">{this.state.data.indicateurTrotteur.ipgEntraineur}</span></TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left"><span class="lato-regular">Aptitude avec le déferrage :</span></TableCell>
                      <TableCell align="right"><span class="lato-regular">{this.state.data.indicateurTrotteur.ipgDeferrage}</span></TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left"><span class="lato-regular">Aptitude sur la corde :</span></TableCell>
                      <TableCell align="right"><span class="lato-regular">{this.state.data.indicateurTrotteur.ipgCorde}</span></TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left"><span class="lato-regular">Aptitude avec le driver / hippodrome :</span></TableCell>
                      <TableCell align="right"><span class="lato-regular">{this.state.data.indicateurTrotteur.ipgDriverHippodrome}</span></TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left"><span class="lato-regular">Spécialiste discipline :</span></TableCell>
                      <TableCell align="right"><span class="lato-regular">{this.state.data.indicateurTrotteur.estSpecialiste}</span></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left"><span class="lato-regular">Régularité :</span></TableCell>
                      <TableCell align="right"><span class="lato-regular">{this.state.data.indicateurTrotteur.estRegulier}</span></TableCell>
                    </TableRow>

                    
                </TableBody>
              </Table>

              <Table style={{marginTop:"2em"}} component={Paper}>
                <TableHead>
                  <TableRow>
                    <TableCell><span class="lato-bold">Driver - {this.state.data.partant.driver}</span></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  
                    <TableRow>
                      <TableCell align="left"><span class="lato-regular">Popularité :</span></TableCell>
                      <TableCell align="right"><span class="lato-regular">{this.state.data.indicateurDriver.popularite_10}</span></TableCell>
                    </TableRow>
                  
                </TableBody>
              </Table>

              <Table style={{marginTop:"2em"}} component={Paper}>
                <TableHead>
                  <TableRow>
                    <TableCell><span class="lato-bold">Entraineur - {this.state.data.partant.entraineur}</span></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  
                    <TableRow>
                      <TableCell align="left"><span class="lato-regular">Popularité :</span></TableCell>
                      <TableCell align="right"><span class="lato-regular">{this.state.data.indicateurEntraineur.popularite_10}</span></TableCell>
                    </TableRow>
                   
                </TableBody>
              </Table>

          

              </div>

              )}
              </Box>    
              </DialogContent>
              <Divider/>
              <DialogActions>
            
              
              </DialogActions>
            </Dialog>

            

        )
    }
}