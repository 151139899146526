import React, { Component } from "react";
import Box from '@mui/material/Box';
import * as Const from "../Constant";

import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Grid from '@mui/material/Grid';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import Paper from '@mui/material/Paper';

import Alert from '@mui/material/Alert';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Snackbar from '@mui/material/Snackbar';
import Drawer from '@mui/material/Drawer';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import DialogAide from "./DialogAide";

export default class ChoixPeriode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      utilisateur:this.props.utilisateur,
      periodes:null,
      periode: null,
      aidePeriode:false,
      aideOption:false
      
    };
    
  }

  componentWillReceiveProps(props) {
    this.setState({ utilisateur: props.utilisateur})
  }

  componentDidMount() {
    this.callPeriode();
  }

  callPeriode = () => {
    
    fetch(
      Const.URL_WS_PERIODES + `?utilisateur=${localStorage.getItem("utilisateur_dtf")}&pays=FRANCE`,
      { retry: 0, retryDelay: 1000 }
    )
      .then((res) => res.json())
      .then((data) => {
        this.setState({periodes:data.periodes});
        this.setState({ periode: data.periodes[0] }, () => {
          this.props.handlePeriode(this.state.periode);
        });
        
      })
      .catch((error) => {
        console.log("Request failed", error);
      });
  }

  handlePeriode = (event) => {
    this.setState({ periode: event.target.value});
    this.props.handlePeriode(event.target.value);
  }

  aidePeriode = () => {
    this.setState({ aidePeriode: true})
  }

  closeAidePeriode = () => {
    this.setState({ aidePeriode: false})
  }



  /************************************************************************************
   * RENDU
   ***********************************************************************************/

  render() {

    return(
      <div>
         {this.state.aidePeriode && (
    <DialogAide titre={"Astuce"} open={true} 
    close={this.closeAidePeriode}
    content="Sélectionnez la période qui sera appliquée à votre sélection lors de l'analyse de performance. La période des trois derniers mois permet d'obtenir une tendance récente."
    />
    )}



      <Card>
      <CardHeader
          avatar={
          <Avatar aria-label="recipe">
              P
          </Avatar>
          }
          action={
          <div>
         
          <IconButton onClick={this.redirectEditeurSelection} aria-label="settings">
              <Tooltip title={<span class="lato-regular">Aide</span>}>
                <HelpOutlineOutlinedIcon onClick={this.aidePeriode} style={{color:"black", cursor:"pointer"}}/>
              </Tooltip>
          </IconButton>
          </div>
          }
          title={<span class="lato-bold" style={{transform:"none", color:"black", fontSize:"15px"}}>Période</span>}
          subheader={<span class="lato-regular" style={{fontSize:"14px"}} >Critère sur la période.</span>}
      />
      <CardContent>
     
      <FormControl fullWidth>
        
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          size="small"
          value={this.state.periode}
          onChange={this.handlePeriode}
        >
         {this.state.periodes != null && this.state.periodes.map((name) => (
            <MenuItem
              key={name.id}
              value={name}
            >
             <span class="lato-regular" style={{fontSize:"14px"}}>{name.label}</span>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      
    
    
      </CardContent>
      </Card>


      </div>


    )
  }
}