import React, { Component } from "react";
import * as Const from "../Constant";
import Box from '@mui/material/Box';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Checkbox from '@mui/material/Checkbox';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';

import Divider from '@mui/material/Divider';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import Drawer from '@mui/material/Drawer';
import ListFiltres from "./ListFiltres";

export default class EditeurSelection extends Component {

    constructor(props) {
        super(props);
        this.state = {
          tab: [],
          selections: [],
          descriptions: [],
          openDialogEnregistrer: false,
          recherche: "",
          selectionNom: "Condition_" + Date.now(),
          rechercheFiltre: "",
          selectionFiltres: "",
          selectedIndex: 0,
          sousCategorie: "TOUT",
          dossier: "Conditions Personnelles",
          openSnackBar: false
        }

        this.tab = [];
    }

    componentWillReceiveProps(props) {
        
    }

    componentDidMount() {
        let o = {};
        o.id = 7;
        o.aide = "Les catégories de course (comme Course A, Course B, etc.) sont souvent déterminées par les performances passées des chevaux. Les chevaux sont classés en fonction de leurs résultats antérieurs et de leur potentiel, ce qui aide à assurer des compétitions équilibrées et compétitives.";
        this.setState({ selectedCritere: o})
        this.wsCriteres();
        this.wsFiltres(o);

        let idConditionModifie = localStorage.getItem("idConditionModifie");

        if(idConditionModifie != null){
          this.loadConditionModifie(idConditionModifie);
          localStorage.removeItem("idConditionModifie")
        }
    }

    loadFiltre = (id) => {
      fetch(
        Const.URL_WS_FILTRE + `?&id=${id}`,
        { retry: 0, retryDelay: 1000 }
       )
        .then((res) => res.json())
        .then((data) => {
           console.log(data);
           this.handleCheckbox(data)
           
        })
        .catch((error) => {
          console.log("Request failed", error);
        });
    }

    loadConditionModifie = (idCondition) => {
      fetch(
        Const.URL_WS_CONDITION + `?utilisateur=${localStorage.getItem("utilisateur_dtf")}&idCondition=${idCondition}`,
        { retry: 0, retryDelay: 1000 }
      )
        .then((res) => res.json())
        .then((data) => {
           console.log(data);
           let tab = data.idFiltres.split(";")
           
           for (let element of tab) {
            if(element > 0){
              this.loadFiltre(element)
            }
           }
        })
        .catch((error) => {
          console.log("Request failed", error);
        });
    }

    closeDialogEnregistrer = () => {
      this.setState({openDialogEnregistrer: false});
    }

    closeSnackBar = () => {
      this.setState({openSnackBar: false});
    }

    openDialogEnregistrer = () => {
      this.setState({selectionNom: "Condition_" + Date.now()});
      this.setState({openDialogEnregistrer: true});
    }

    handleSelect = (event) => {
      this.setState({ dossier: event.target.value});
    }
    
    handleSousCategorie = (event) => {
      
      this.setState({ sousCategorie: event.target.value }, () => {
        this.wsCriteres();
      });
      
    }

    redirectHome = () => {
      window.location.href = Const.URL + "editeur";
    }

    handleCheckbox = (o) => {
      let copytab = this.state.tab;
      let copySelections = this.state.selections;
      let copyDescriptions = [];

      if(copytab[o.id] == true){
        copytab[o.id] = false;  
        copySelections = copySelections.filter(item => item.id !== o.id);
      }else{
        copytab[o.id] = true;
        copySelections.push(o);
      }

      let filtres = "";  
      let aide = "";
      for (const item of copySelections) {
        filtres +=  ';' + item.id;

        let description= {};
        description  = { ...item };
  
        // Rechercher un objet avec le même idCritere
        let existingSelection = copyDescriptions.find(des => des.idCritere === description.idCritere);
        
        if (existingSelection) {
            // Si un objet avec le même idCritere existe, concaténer la valeur
            existingSelection.valeur += ` , ${description.valeur}`;
        } else {
            // Sinon, ajouter le nouvel objet à la liste
            copyDescriptions.push(description);
        }
      }

      for (const item of copyDescriptions) {
        aide += item.label + "," + item.valeur + ";";
      }

      this.setState({ descriptions: copyDescriptions})
      this.setState({ selectionFiltres: filtres})
      this.setState({ selectionDescription: aide})
      this.setState({ tab: copytab})
      this.setState({ selections: copySelections})
    }

    keyPress = (e) => {
      if(e.keyCode == 13){
         this.wsCriteres();
      }
   }

   
   keyPressFiltre = (e) => {
    if(e.keyCode == 13){
      this.wsFiltres(this.state.selectedCritere);
    }
  }

  closeDrawerAide = () => {
    this.setState({openDrawerAide: false});
  }

  handleAide = (obj) => {
    this.setState({ openDrawerAide: true});
  }

   wsFiltresByCritere = (obj) => {
    fetch(
      Const.URL_WS_FILTRES + `?idCritere=${obj.idCritere}&search=`,
      { retry: 0, retryDelay: 1000 }
    )
    .then((res) => res.json())
    .then((data) => {
        this.setState({ filtres: data})
        let aide = this.state.aideCritere;
        this.setState({ descriptionAide: aide[obj.idCritere]})
    })
    .catch((error) => {
        console.log("Request failed", error);
    });
  }

  handleCritereSelect = (index, obj) => {
    this.setState({ selectedIndex: index });
    this.setState({ selectedCritere: obj });
    this.wsFiltres(obj);
  }

    wsFiltres = (obj) => {
      fetch(
        Const.URL_WS_FILTRES + `?idCritere=${obj.id}&search=${this.state.rechercheFiltre}`,
        { retry: 0, retryDelay: 1000 }
      )
      .then((res) => res.json())
      .then((data) => {
          this.setState({ filtres: data})
          this.tab[obj.id] = obj.aide
          this.setState({ aideCritere: this.tab})
          this.setState({descriptionAide: obj.aide})
      })
      .catch((error) => {
          console.log("Request failed", error);
      });
    }

    handleZoneRechercheFiltre = (valeur) => {
      this.setState({ rechercheFiltre: valeur }, () => {
        this.wsFiltres(this.state.selectedCritere);
      });
    }

    wsSelectionSave = () => {
      let utilisateur = localStorage.getItem("utilisateur_dtf");
      fetch(
        Const.URL_WS_CONDITION_SAVE + `?utilisateur=${utilisateur}
        &conditionNom=${this.state.selectionNom}
        &conditionDescription=${this.state.selectionDescription}
        &conditionFiltres=${this.state.selectionFiltres}
        &conditionDossier=${this.state.dossier}`,
        { retry: 0, retryDelay: 1000 }
      )
      .then((res) => res.json())
      .then((data) => {
        if(data == true){
          this.setState({openSnackBar: true});
        }else{
          alert("Erreur");
        }
      })
      .catch((error) => {
          console.log("Request failed", error);
      });
      this.closeDialogEnregistrer();
    }

    wsCriteres = () => {    
        fetch(
            Const.URL_WS_CRITERES + `?categorie=CONDITION_COURSE&sousCategorie=${this.state.sousCategorie}&search=${this.state.recherche}`,
            { retry: 0, retryDelay: 1000 }
        )
        .then((res) => res.json())
        .then((data) => {
            this.setState({ criteres: data})
        })
        .catch((error) => {
            console.log("Request failed", error);
        });
    }


    render() {
        return (
            <div>
               <Box sx={{ width: '100vw', maxWidth: '100vw', height:'55px', maxHeight:'55px', bgcolor: 'white' , overflow:'hidden', overflowX:'hidden', borderBottomWidth:"5px", borderBottomStyle:"inset" }}>
               <Grid container spacing={1}>
               <Grid item xs={6}>
               <div style={{display:"flex"}}>
                  <div>
                    <img style={{marginTop:"0.2em", marginLeft:"1em"}} width='33' height={45} src={"https://dataturf.fr/images/logo_noir.png"}></img>
                  </div>
                  <div style={{marginTop:"0.7em"}}>
                      <span style={{fontFamily:"Racing Sans One", fontSize:"25px", marginLeft:"0.5em"}}>TROTSTATS</span> 
                      <span style={{fontFamily:"Racing Sans One", fontSize:"20px", marginLeft:"0.5em"}}>- Éditeur de condition course</span> 
                  </div>
                </div>
                </Grid>
                <Grid item xs={6}>
                  <div style={{float:"right", margin:"0.5em"}}>
                  <IconButton
                    aria-label="close"
                    style={{marginLeft:"1em"}}
                    onClick={() => this.openDialogEnregistrer()}
                  >
                  <CloudUploadIcon style={{color:"black"}} />
                  </IconButton>

                  <IconButton
                    aria-label="close"
                    style={{marginLeft:"1em"}}
                    onClick={this.redirectHome}
                  >
                  <CloseIcon style={{color:"black"}} />
                  </IconButton>
                </div>
                </Grid>
                
                </Grid>
               </Box>
            
            
            <div style={{display:'flex'}}>
            
            <Box sx={{ width: '30vw', maxWidth: '30vw', height:'calc(100vh - 60px)', maxHeight:'calc(100vh - 60px)', bgcolor: 'white' , overflow:'scroll', overflowX:'hidden' }}>
                <List>
                <ListSubheader>
                <span class="lato-bold" style={{transform:"none", color:"black", fontSize:"15px"}}>Liste des critères</span>
                  <FormControl style={{marginTop:"1em"}} fullWidth>
                  <InputLabel id="demo-simple-select-label"><span style={{color:"black"}} class="lato-regular">Catégorie</span></InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.sousCategorie}
                    label="Catégorie"
                    defaultValue={"Toutes les catégories"}
                    onChange={this.handleSousCategorie}
                  >
                  <MenuItem value={"TOUT"}><span class="lato-regular" style={{fontSize:"14px"}}>Toutes les catégories</span></MenuItem>
                  <MenuItem value={"INFORMATION_COURSE"}><span class="lato-regular" style={{fontSize:"14px"}}>Informations courses</span></MenuItem>
                  <MenuItem value={"INDICATEUR_COURSE"}><span class="lato-regular" style={{fontSize:"14px"}}>Indicateurs courses</span></MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  style={{marginTop:"1em", marginBottom:"1em"}}
                  fullWidth
                  InputProps={{
                    startAdornment: <SearchOutlinedIcon/>,
                    className: "lato-regular",
                  }}
                  id="outlined-controlled"
                  onKeyDown={this.keyPress}
                  autoComplete={"off"}
                  size="small"
                  label={<span style={{color:"black"}} class="lato-regular">Recherche</span>}
                  value={this.state.recherche}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({recherche: event.target.value});
                  }}
                />

                </ListSubheader>
                {this.state.criteres != null && this.state.criteres.criteres.map((o, index) => (
                  <div>
                  <ListItem style={{ backgroundColor: index === this.state.selectedIndex ? '#D4D4D4' : 'transparent' }}>                   
                       
                  {o.isPro == "N" && (
                          <ListItemText onClick={() => this.handleCritereSelect(index, o)} style={{cursor:"pointer"}} 
                          primary={
                            <div> 
                                <span class="lato-regular" style={{fontSize:"14px"}}>{o.label}</span> 
                                <HelpCenterOutlinedIcon onClick={() => this.handleAide(o)} style={{float:"right", color:"gray"}}/>
                            </div>
                          } 
                          />  
                        )}

                        {o.isPro == "O" && (
                          <ListItemText onClick={() => this.handleCritereSelect(index, o)} style={{cursor:"pointer"}} 
                          primary={
                            <div> 
                              
                              <span class="lato-regular" style={{fontSize:"14px"}}>{o.label}</span> 
                              <HelpCenterOutlinedIcon onClick={() => this.handleAide(o)} style={{float:"right", color:"gray"}}/> 
                              <Chip style={{marginLeft:"0.5em", marginRight:"0.5em", float:"right"}} size="small" label="beta" color="primary" />
                            </div>
                          } 
                          />  
                        )}

                  </ListItem>
                  <Divider variant="inset" />
                  </div>
                ))}

                {this.state.criteres != null && this.state.criteres.criteres.length == 0 && (
                  <Alert style={{margin:"1em"}} severity="warning"><span style={{fontSize:"14px"}} class="lato-regular">Aucun résultat.</span></Alert>
                )}

                </List>
            </Box>

            <Box sx={{ width: '70vw', maxWidth: '70vw', height:'calc(100vh - 60px)', maxHeight:'calc(100vh - 60px)', bgcolor: 'red', overflow:'hidden', overflowX:'hidden' }}>

            <div>
                <Box sx={{ width: '70vw', maxWidth: '70vw', height:'calc(50vh - 10px)', maxHeight:'calc(50vh - 10px)', bgcolor: 'white', overflow:'scroll', overflowX:'hidden', borderBottomWidth:"10px", borderBottomStyle:"inset" }}>
                <ListFiltres filtres={this.state.filtres}
                tab={this.state.tab} 
                selectedCritere={this.state.selectedCritere}
                handleZoneRechercheFiltre={this.handleZoneRechercheFiltre}
                handleCheckbox={this.handleCheckbox}
                />
                    
                </Box>

                <Box sx={{ width: '70vw', maxWidth: '70vw', height:'calc(50vh - 10px)', maxHeight:'calc(50vh - 60px)', bgcolor: 'white', overflow:'scroll', overflowX:'hidden' }}>
                
                <List>
                <ListSubheader>
                <span class="lato-bold" style={{transform:"none", color:"black", fontSize:"15px"}}>Condition sur les courses</span>
                </ListSubheader>

                {this.state.descriptions != null && this.state.descriptions.map((s, index) => (
                  <div style={{margin:"1em"}}>
                  <ListItem>                   
                    <Grid container spacing={2}>
                      <Grid item xs={1}>
                        <AutoFixHighOutlinedIcon onClick={() => this.wsFiltresByCritere(s)}/>
                      </Grid>
                      <Grid item xs={4}>
                        <span onClick={() => this.wsFiltresByCritere(s)} class="lato-regular" style={{cursor:"pointer", fontSize:"14px"}}>{s.label}</span> 
                      </Grid>
                      <Grid item xs={7}>
                      <span onClick={() => this.wsFiltresByCritere(s)} class="lato-regular" style={{cursor:"pointer", fontSize:"14px"}}>{s.valeur}</span>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider variant="inset" />
                  </div>
                ))}

                {this.state.descriptions == null ||this.state.descriptions == "" && (
                     <div class="loadingImage">
                     <center>
                    <img width={"20%"} height={"20%"} style={{opacity:0.1}} src="https://dataturf.fr/images/brain.jpg" alt="Brain Image"/>
                    </center>
                     </div>
                )}
                </List>
                    
                </Box>
              </div>
                
            </Box>

            <Dialog
              open={this.state.openDialogEnregistrer}
              onClose={this.closeDialogEnregistrer}
              fullWidth
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
               {<span class="lato-bold" style={{fontSize:"18px"}}>Enregistrer</span>}
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={this.closeDialogEnregistrer}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
              <CloseIcon style={{color:"black"}} />
              </IconButton>
              <Divider/>
              <DialogContent>
                
                <TextField
                  style={{marginTop:"0.5em"}}
                  size="small"
                  fullWidth
                  autoComplete="off"
                  id="outlined-controlled"
                  label={<span class="lato-regular">Nom</span>}
                  defaultValue={this.state.selectionNom}
                  InputProps={{
                    className: "lato-regular",
                  }}
                  value={this.state.selectionNom}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({selectionNom: event.target.value});
                  }}
                />

                <FormControl fullWidth style={{marginTop:"1.5em", marginBottom:"1em"}}>
                  <InputLabel id="demo-simple-select-label"><span style={{color:"black"}} class="lato-regular">Dossier</span></InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.dossier}
                    label="Dossier"
                    defaultValue={"Conditions Personnelles"}
                    onChange={this.handleSelect}
                  >
                  <MenuItem value={"Conditions Personnelles"}><span class="lato-regular" style={{fontSize:"14px"}}>Conditions personnelles</span></MenuItem>
                  <MenuItem value={"Brouillon"}><span class="lato-regular" style={{fontSize:"14px"}}>Brouillon</span></MenuItem>
                  </Select>
                </FormControl>

              </DialogContent>
              <Divider/>
              <DialogActions>

              <IconButton
                    aria-label="close"
                    style={{marginLeft:"1em"}}
                    onClick={this.wsSelectionSave}
                  >
                  <CheckOutlinedIcon style={{color:"black"}} />
                  </IconButton>

                
               
              </DialogActions>
            </Dialog>

            <Snackbar
              open={this.state.openSnackBar}
              autoHideDuration={3000}
              severity="success"
              onClose={this.closeSnackBar}
              message="Enregistrer"
            >
               <Alert
                  onClose={this.closeSnackBar}
                  severity="succes"
                  variant="filled"
                  sx={{ width: '100%' }}
                >
                <span class="lato-regular" style={{fontSize:"14px"}}>Condition Course enregistrée</span>
              </Alert>
            </Snackbar>


            <Drawer style={{width:"800px", height:"99%"}} open={this.state.openDrawerAide}  anchor={"right"} onClose={this.closeDrawerAide}>
            <Box sx={{ width: 800, height:"99%" }} role="presentation">
            <embed src={"https://www.trotstats.fr/documentations/criteres/" + this.state.descriptionAide} width="100%" height="100%" type='application/pdf'/>

            </Box>
            </Drawer>

            </div>
            </div>

        )
    }
}